import config from '../config'

// fetch
const api = ({
  method,
  url = '',
  data,
  tokenExpiredCallback = () => { },
  tokenHeaders = {},
}) => {
  const extraconfig = {};
  console.log(data);

  // request params
  const requestParams = {
    method,
    cache: 'no-cache',
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json',
      ...extraconfig,
      ...tokenHeaders,
    },
  }

  if (data) requestParams.body = JSON.stringify(data);

  return fetch(`${config.apiBaseUrl}${url}`, requestParams)
    .catch(err => {
      console.log('error', err);
      throw err
    })
    .then(response => {
      console.log(response);
      if (response.status === 401 || response.message == 'Unauthorized') {
        tokenExpiredCallback();
      }
      return response.json().then(data => ({ data, headers: response.headers }));
    })
}

export default api
