const PHOTO_CART_KEY = 'TRAVELPIX_PHOTO_CART_KEY';
const COUPON_KEY = 'TRAVELPIX_COUPON_KEY';
const ACCESS_TOKEN = 'TRAVELPIX_ACCESS_TOKEN';

const setDataLocalByKey = (key: string, data: string) => {
  localStorage.setItem(key, data);
};

const getDataLocalByKey = (key: string): string => {
  const dataStr = localStorage.getItem(key);
  if (dataStr === null) return '';
  return dataStr;
};

const checkExistingKey = (key: string): boolean => {
  const dataStr = localStorage.getItem(key);
  return dataStr !== null;
};

const removeDataLocalByKey = (key: string) => {
  if (checkExistingKey(key)) {
    localStorage.removeItem(key);
  }
};

const checkExistingSessionKey = (key: string): boolean => {
  const dataStr = sessionStorage.getItem(key);
  return dataStr !== null;
};

const setDataSessionByKey = (key: string, data: string) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(key, data);
  }
};

const getDataSessionByKey = (key: string): string => {
  if (typeof window !== 'undefined') {
    const dataStr = sessionStorage.getItem(key);
    if (dataStr === null || dataStr === undefined) return '';
    return dataStr;
  }
  return '';
};

export { PHOTO_CART_KEY, COUPON_KEY, ACCESS_TOKEN, setDataLocalByKey, getDataLocalByKey, checkExistingKey, removeDataLocalByKey, setDataSessionByKey, getDataSessionByKey, checkExistingSessionKey };
