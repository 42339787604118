import { AuthParam } from '../reducers/auth/model';
import api from '../services/api';
import { navigate } from 'gatsby';
import { ACCESS_TOKEN, getDataSessionByKey } from '../utils/localstore';
import { CheckoutPhoto } from '../reducers/gallery/model';

export const getAuthMock = (param?: AuthParam) => {
  return new Promise((resolve, reject) => {
    if (param) {
      setTimeout(() => {
        reject(new Error('Not authentication'));
      }, 2000);
    }

    setTimeout(() => {
      resolve({
        isAuthenticated: true,
      });
    }, 2000);
  });
};

export const getGalleryPhotos = () => {
  return new Promise((resolve, reject) => {
    api({
      method: 'get',
      url: `/session-photo/gallery/web`,
      data: null,
      tokenExpiredCallback: () => {
        if (typeof window !== 'undefined') {
          navigate('/');
        }
      },
      tokenHeaders: {
        Authorization: 'Bearer ' + getDataSessionByKey(ACCESS_TOKEN),
      }
    }).then(response => {
      resolve({
        data: response.data.data
      })
    })
      .catch(_ =>
        reject(new Error('An error ocurred. Please refresh the page.'))
      )
  });
}

export const getPaymentIntentClientSecret = (param?: CheckoutPhoto) => {
  console.log(param);
  return new Promise((resolve, reject) => {
    api({
      method: 'post',
      url: `/payments/client-secret`,
      data: param,
      tokenExpiredCallback: () => navigate('/'),
      tokenHeaders: {
        Authorization: 'Bearer ' + getDataSessionByKey(ACCESS_TOKEN),
      }
    }).then(response =>
      resolve({
        data: response.data
      }))
      .catch(_ =>
        reject(new Error('An error ocurred. Please refresh the page.'))
      )
  });
}

export const checkCoupon = (code: string) => {
  return new Promise((resolve, reject) => {
    api({
      method: 'get',
      url: `/payments/discount?code=${code}`,
      data: null,
      tokenExpiredCallback: () => navigate('/'),
      tokenHeaders: {
        Authorization: 'Bearer ' + getDataSessionByKey(ACCESS_TOKEN),
      }
    }).then(response =>
      resolve({
        data: response.data
      }))
      .catch(_ =>
        reject(new Error('An error ocurred. Please refresh the page.'))
      )
  });
}

export const getPhotoPrices = () => {
  return new Promise((resolve, reject) => {
    api({
      method: 'get',
      url: `/session-photo/prices`,
      data: null,
      tokenExpiredCallback: () => {
        if (typeof window !== 'undefined') {
          navigate('/');
        }
      },
      tokenHeaders: {
        Authorization: 'Bearer ' + getDataSessionByKey(ACCESS_TOKEN),
      }
    }).then(response => {
      resolve({
        data: response.data.data
      })
    })
      .catch(_ =>
        reject(new Error('An error ocurred. Please refresh the page.'))
      )
  });
}
