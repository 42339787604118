// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-routes-about-us-page-index-tsx": () => import("./../src/routes/AboutUsPage/index.tsx" /* webpackChunkName: "component---src-routes-about-us-page-index-tsx" */),
  "component---src-routes-checkout-page-index-tsx": () => import("./../src/routes/CheckoutPage/index.tsx" /* webpackChunkName: "component---src-routes-checkout-page-index-tsx" */),
  "component---src-routes-contact-page-index-tsx": () => import("./../src/routes/ContactPage/index.tsx" /* webpackChunkName: "component---src-routes-contact-page-index-tsx" */),
  "component---src-routes-cookie-policy-page-index-tsx": () => import("./../src/routes/CookiePolicyPage/index.tsx" /* webpackChunkName: "component---src-routes-cookie-policy-page-index-tsx" */),
  "component---src-routes-faq-page-index-tsx": () => import("./../src/routes/FAQPage/index.tsx" /* webpackChunkName: "component---src-routes-faq-page-index-tsx" */),
  "component---src-routes-gallery-page-index-tsx": () => import("./../src/routes/GalleryPage/index.tsx" /* webpackChunkName: "component---src-routes-gallery-page-index-tsx" */),
  "component---src-routes-landing-page-index-tsx": () => import("./../src/routes/LandingPage/index.tsx" /* webpackChunkName: "component---src-routes-landing-page-index-tsx" */),
  "component---src-routes-photographer-page-index-tsx": () => import("./../src/routes/PhotographerPage/index.tsx" /* webpackChunkName: "component---src-routes-photographer-page-index-tsx" */),
  "component---src-routes-privacy-policy-page-index-tsx": () => import("./../src/routes/PrivacyPolicyPage/index.tsx" /* webpackChunkName: "component---src-routes-privacy-policy-page-index-tsx" */),
  "component---src-routes-register-page-index-tsx": () => import("./../src/routes/RegisterPage/index.tsx" /* webpackChunkName: "component---src-routes-register-page-index-tsx" */),
  "component---src-routes-reset-password-page-index-tsx": () => import("./../src/routes/ResetPasswordPage/index.tsx" /* webpackChunkName: "component---src-routes-reset-password-page-index-tsx" */),
  "component---src-routes-terms-and-conditions-page-index-tsx": () => import("./../src/routes/TermsAndConditionsPage/index.tsx" /* webpackChunkName: "component---src-routes-terms-and-conditions-page-index-tsx" */)
}

