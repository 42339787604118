import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store, sagaMiddleware } from './src/config/storeRedux';
import { rootSaga } from './src/reducers';
import initI18nService from './src/config/i18n';
import 'normalize.css';
import 'modern-normalize';
import 'react-datepicker/dist/react-datepicker.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

export const wrapRootElement = ({ element }) => {
  const reduxStore = store();
  sagaMiddleware.run(rootSaga);
  initI18nService();
  return (
    <Elements stripe={stripePromise}>
      <Provider store={reduxStore}>
        {element}
      </Provider>
    </Elements>
  );
};
